import React, { useContext, useEffect, useState } from 'react';
import { Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { useMediaQuery } from 'react-responsive';
import StoreContext from '~/layouts/StoreContext';
import { getImage, getImageFixed } from '~/helpers';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { HeadingLarge, BodyText } from '~/components/.base/headings';
import { WhiteButton } from '~/components/.base/buttons';
import { PageContainer, CenteredContainer, FlexBox } from '~/components/.base/containers';
import './404.scss';

const NotFoundPage = () => {
    const { setHeaderWhite } = useContext(StoreContext);
    const [loaded, setLoaded] = useState(false);
    const [trigger, setTrigger] = useState(false);
    useEffect(() => {
        console.log('effect loop 1');
        if (window && loaded) {
            setHeaderWhite(false);
        }
    }, [loaded]);
    useEffect(() => {
        console.log('effect loop 2');
        if (window) {
            setLoaded(true);
            console.log(
                document.querySelector('.newsletter'),
                document.querySelector('#sticky-footer')
            );

            if (document.querySelector('.newsletter') && document.querySelector('#sticky-footer')) {
                document.querySelector('.newsletter').classList.add('d-none');
                document.querySelector('#sticky-footer').classList.add('d-none');
            } else {
                setTimeout(() => {
                    setTrigger(trigger => !trigger);
                }, 500);
            }
        } else {
            setTimeout(() => {
                setTrigger(trigger => !trigger);
            }, 500);
        }
    }, [trigger]);
    const isMobile = useMediaQuery({ query: `(max-width: 767px)` });
    const images = useStaticQuery(graphql`
        query {
            mobileImages: allFile(
                filter: {
                    absolutePath: { regex: "/images/homepage/slideshow/" }
                    extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                }
            ) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fixed(width: 263, height: 326) {
                                aspectRatio
                                ...GatsbyImageSharpFixed_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
            smallerImages: allFile(
                filter: {
                    absolutePath: { regex: "/images/homepage/slideshow/" }
                    extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                }
            ) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(
                                maxWidth: 200
                                quality: 80
                                sizes: "(max-width: 500px) 6vw, (max-width: 1200px) 12vw, 200px"
                            ) {
                                aspectRatio
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
            mediumImages: allFile(
                filter: {
                    absolutePath: { regex: "/images/homepage/slideshow/" }
                    extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                }
            ) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(
                                maxWidth: 500
                                quality: 80
                                sizes: "(max-width: 500px) 30vw, 500px"
                            ) {
                                aspectRatio
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
            largerImages: allFile(
                filter: {
                    absolutePath: { regex: "/images/homepage/slideshow/" }
                    extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                }
            ) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1000, quality: 80) {
                                aspectRatio
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
            homepageImages: allFile(
                filter: {
                    extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                    absolutePath: { regex: "/images/homepage/" }
                }
            ) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1600, quality: 80) {
                                aspectRatio
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                            original {
                                width
                            }
                        }
                    }
                }
            }
        }
    `);
    return (
        <>
            <SEO title="404: Not found" />
            <Criteo />
            <PageContainer className="four-oh-four">
                <CenteredContainer className="details">
                    <HeadingLarge md="0 0 2rem">
                        <span className="red">MAYDAY!</span>
                    </HeadingLarge>
                    <BodyText>You are lost at sea. Let's get you back to shore.</BodyText>
                    <FlexBox as="ul" wrap="wrap" justify="center" className="button-cont">
                        <li>
                            <WhiteButton as={Link} to="/">
                                Go home
                            </WhiteButton>
                        </li>
                        <li>
                            <WhiteButton as={Link} to="/shop">
                                Ship lobster from Maine
                            </WhiteButton>
                        </li>
                        <div className="full-width"></div>
                        <li>
                            <WhiteButton as={Link} to="/locations">
                                FIND A TRUCK or RESTAURANT NEARBY
                            </WhiteButton>
                        </li>
                    </FlexBox>
                </CenteredContainer>
                <Image
                    fluid={getImage(images.mediumImages.edges, 'fisherman_boat')}
                    className="fisherman_boat"
                    alt=""
                    loading="eager"
                    fadeIn={false}
                    durationFadeIn={100}
                />
                <Image
                    fluid={getImage(images.largerImages.edges, 'landscape_water_left')}
                    className="landscape_water"
                    alt=""
                    loading="eager"
                    fadeIn={false}
                    durationFadeIn={100}
                />
                <Image
                    fluid={getImage(images.largerImages.edges, 'landscape_water_right')}
                    className="landscape_water"
                    alt=""
                    loading="eager"
                    fadeIn={false}
                    durationFadeIn={100}
                />
                <Image
                    fluid={getImage(images.smallerImages.edges, 'lighthouse')}
                    className="lighthouse"
                    alt=""
                    loading="eager"
                    fadeIn={false}
                    durationFadeIn={100}
                />
                {/* Removed "big_seagull" image to fix Accessible Issue #281 */}
                {/* <Image
                  fluid={getImage(images.homepageImages.edges, "big_seagull")}
                  className="big_seagull"
                  alt=""
                /> */}
                <Image
                    fluid={getImage(images.homepageImages.edges, 'seagull_medium')}
                    className="medium_seagull"
                    alt=""
                />
                <Image
                    fluid={getImage(images.homepageImages.edges, 'seagull_flock')}
                    className="seagull_flock"
                    alt=""
                />
            </PageContainer>
        </>
    );
};

export default NotFoundPage;
